x<template>
  <div class="login-container">
    <div class="login-left">
      <img src="../../assets/img/login-logon.png" alt="">
    </div>
    <div class="login-right">
      <div class="content">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
                 label-position="left">

          <el-form-item prop="username">
                        <span class="svg-container title-txt">
                            欢迎登录
                        </span>
            <el-input style="width:470px;" ref="username" v-model="loginForm.username" placeholder="请输入账号"
                      name="username" type="text" tabindex="1" auto-complete="on"/>
          </el-form-item>

          <el-form-item prop="password">
            <el-input style="width:470px;" :key="passwordType" ref="password" v-model="loginForm.password"
                      :type="passwordType" placeholder="请输入密码" name="password" tabindex="2" auto-complete="on"
                      @keyup.enter.native="handleLogin"/>
          </el-form-item>

          <div class="checkbox-btn">
            <el-checkbox v-model="remChecked">记住您的密码</el-checkbox>
            <!--            <el-checkbox v-model="autoChecked">自动登录</el-checkbox>-->
          </div>
          <el-button :loading="loadings" type="primary" class="loginbtn" @click.native.prevent="handleLogin">登录
          </el-button>

        </el-form>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 3) {
        callback(new Error('密码不能少于3位'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: '',
        loginType: 0
      },
      loginRules: {
        username: [{required: true, message: '请输入内容', trigger: 'blur'}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}]
      },
      loadings: false,
      remChecked: false,
      autoChecked: false,
      passwordType: 'password',
    }
  },
  mounted () {
    this.getCookie();
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loadings = true

          // this.loginForm.password =  this.$md5(this.loginForm.password);

          if (this.remChecked == true) {
            //传入账号名，密码，和保存天数3个参数
            this.setCookie(this.loginForm.username, this.loginForm.password, 7);
          }else {
            //清空Cookie
            this.clearCookie();
          }

          this.$api.login(this.loginForm).then((res) => {
            if (res && res.data.code == 200) {
              // this.getUserInfo();
              this.$store.commit('SET_TOKEN', res.data.data.accessToken)
              this.$router.replace({
                path: '/home',
              })
            }
          })
          this.loadings = false
        } else {
          return false
        }
      })
    },
    // 获取当前登录的用户信息
    // getUserInfo() {
    //   let that = this;
    //   that.$api.getloginInfo().then((res) => {
    //     if (res && res.data.code == 200) {
    //       sessionStorage.setItem("nickName", res.data.data.nickName);
    //       sessionStorage.setItem("loginName", res.data.data.loginName);
    //       sessionStorage.setItem("nickNameId", res.data.data.id);
    //       sessionStorage.setItem("avatar", res.data.data.avatar);
    //     }
    //   })
    // },
    //设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie = "usernameQS" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie = "passwordQS" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie = "autoCheckedQS" + "=" + this.autoChecked;
    },
    //读取cookie
    getCookie: function() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('='); //再次切割
          //判断查找相对应的值
          if (arr2[0] == 'usernameQS') {
            this.loginForm.username = arr2[1]; //保存到保存数据的地方
          } else if (arr2[0] == 'passwordQS') {
            this.loginForm.password = arr2[1];
          }else {
            this.autoChecked = arr2[1]==='true'?true:false;
          }
        }
        if(this.autoChecked) {
          this.$api.login(this.loginForm).then((res) => {
            if (res && res.data.code == 200) {
              this.$store.commit('SET_TOKEN', res.data.data.accessToken)
              this.$router.replace({
                path: '/home',
              })
            }
          })
        }
        this.remChecked=true
      }else{
        this.remChecked=false
      }
    },
    //清除cookie
    clearCookie: function() {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    }
  }
}
</script>

<style lang="less" scoped>
p {
  padding: 0;
  margin: 0;
}

.login-container {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  // min-width: 1900px;
  // min-height: 1080px;
  margin: 0 auto;

  .login-left {
    width: 37%;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .login-left-info {
      width: 400px;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      right: 0;
      bottom: 0;
      // padding: 175px 180px 0 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .head {
        display: flex;

        .left {
          img {
            width: 85px;
            height: 45px;
          }
        }

        .right {
          margin-left: 20px;
          color: #fff;

          .zname {
            font-size: 26px;
            letter-spacing: 4px;
          }

          .ename {
            font-size: 16px;
            margin-top: 2px;
            letter-spacing: 1px;

            span {
              margin-left: 4px;
            }
          }
        }
      }

      .name {
        font-size: 36px;
        font-family: SourceHanSansCN, SourceHanSansCN-Bold;
        font-weight: 700;
        color: #ffffff;
        line-height: 54px;
        margin-top: 177px;
      }

      .text {
        font-size: 20px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        margin-top: 70px;
      }
    }
  }

  .login-right {
    // flex: 1;
    width: calc(100vw - 37%);
    height: 100%;
    // padding: 0 370px;
    background: #ffffff;
    position: relative;

    .top {
      position: absolute;
      top: 30px;
      left: 30px;
      height: 142px;
      width: 275px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .bottom {
      position: absolute;
      bottom: 30px;
      right: 30px;
      width: 275px;
      height: 200px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }



    .content {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 36px;
        font-weight: 600;
        color: #27272e;
        line-height: 50px;
        margin-top: 173px;
      }

      .title_e {
        margin-top: 17px;
        font-size: 20px;
        height: 28px;
        font-weight: 300;
        color: #27272e;
        line-height: 28px;
      }

      .login-form {
        //margin-top: 120px;

        /deep/ .el-input__inner {
          height: 60px;
          width: 670px;
          font-size: 16px;
          background-color: #F7FBFF !important;
          border: none;
        }

        .svg-container {
          height: 22px;
          opacity: 1;
          font-size: 24px;
          //font-weight: 600;
          //color: #425466;
          color: #171616;
          line-height: 22px;
          margin-bottom: 28px;
          display: block;
        }

        .loginbtn {
          width: 670px;
          margin-top: 20px;
          height: 60px;
          background: rgb(91,144,220);
          border-radius: 5px;
          box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.09),
          0px 1px 3px 0px rgba(50, 50, 71, 0.08);
          font-size: 22px;
          font-weight: 600;
          color: #ffffff;
          letter-spacing: 4px;
        }

        .tip {
          font-size: 13px;
          font-weight: 400;
          text-align: center;
          color: #7a7a9d;
          line-height: 18px;
          margin-top: 27px;
        }

        .wxLoginBtn {
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: 400;
          color: #27272e;
          background: #ffffff;
          border-radius: 8px;
          margin-top: 36px;
          box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.09),
          0px 1px 3px 0px rgba(50, 50, 71, 0.08);

          img {
            width: 26px;
            height: 26px;
            margin-right: 20px;
          }
        }
      }
    }

  }
}
.checkbox-btn {
  width: 670px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  /deep/.el-checkbox__label {
    &:hover {
      color: rgb(91,144,220);
      cursor: pointer;
    }
  }
}
</style>
